/* TitlePage.css */
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
}

.subtitle {
  font-size: 1.2rem;
  margin: 0;
}

.body {
  margin-bottom: 20px;
  line-height: 1.5;
}

.footer {
  border-top: 1px solid #ccc;
  padding-top: 10px;
}
